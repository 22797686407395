<script lang="ts" setup>
import type { Game } from "@/types";

defineProps<{ games: Game[]; title: string; isGuest: boolean; route?: string; isSection?: boolean }>();
const emit = defineEmits<{ (event: "update"): void }>();
const { t } = useT();

const { handleToggleToFavoriteClick } = useAddToFavorite();

const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const prepareImgUrl = useImage();

const { open } = useTaoModals();
const { handleOpenGame } = useOpenGame(open);

const getTitle = (title?: string) => {
	if (!title) {
		return "";
	}
	return title?.toLowerCase()?.replace(/ /g, "-");
};

const imagePattern = `url(${prepareImgUrl(`/nuxt-img/card-games/pattern.webp`, {
	format: "webp",
	width: 300
})})`;
const handleFavoriteClick = (game: Game) => {
	handleToggleToFavoriteClick(game || {});
	emit("update");
};
</script>

<template>
	<template v-if="!isGuest && games?.length">
		<MSliderWrapper class="slider-desktop" :list="games" :title="title" :navigation="!isSection" :isSection="isSection">
			<template #default="{ item }">
				<template v-if="!isSection">
					<MGame
						class="games-list__item"
						:is-guest="isGuest"
						:game="item!"
						:image="`${baseImageUrl}${item?.logo || item?.img}`"
						:image-pattern="imagePattern"
						skipGamePreview
						@toggle-favorite="handleFavoriteClick(item as Game)"
						@play="handleOpenGame(item?.slug)"
					/>
				</template>

				<template v-else>
					<div class="game-col">
						<MGame
							v-if="item[0]"
							class="games-list__item games-list__item--section"
							:is-guest="isGuest"
							:game="item[0]"
							:image="`${baseImageUrl}${item[0]?.logo || item[0]?.img}`"
							:image-pattern="imagePattern"
							skipGamePreview
							@toggle-favorite="handleFavoriteClick(item[0])"
							@play="handleOpenGame(item[0]?.slug)"
						/>
						<MGame
							v-if="item[1]"
							class="games-list__item games-list__item--section"
							:is-guest="isGuest"
							:game="item[1]"
							:image="`${baseImageUrl}${item[1]?.logo || item[1]?.img}`"
							:image-pattern="imagePattern"
							skipGamePreview
							@toggle-favorite="handleFavoriteClick(item[1])"
							@play="handleOpenGame(item[1]?.slug)"
						/>
					</div>
				</template>
			</template>
			<template #viewAll>
				<NuxtLink v-if="route" :to="route">
					<AText type="base" :data-tid="`slider-${getTitle(title)}-all`" :modifiers="['link', 'underline']">{{
						t("View all")
					}}</AText>
				</NuxtLink>
			</template>
		</MSliderWrapper>
	</template>
</template>

<style lang="scss" scoped>
.game-col {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.games-list__item {
	width: 163px;
	min-height: 286px;
}

.games-list__item--section {
	@include media-breakpoint-down(md) {
		width: auto;
		height: auto;
		min-width: 104px;
		min-height: auto;
	}
}
</style>
